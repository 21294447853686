import { render, staticRenderFns } from "./TestimonialSlider.vue?vue&type=template&id=709d0dfb&scoped=true&"
import script from "./TestimonialSlider.js?vue&type=script&lang=js&"
export * from "./TestimonialSlider.js?vue&type=script&lang=js&"
import style0 from "./TestimonialSlider.scss?vue&type=style&index=0&id=709d0dfb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "709d0dfb",
  null
  
)

export default component.exports