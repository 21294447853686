import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "../pages/Homepage/Homepage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home-page",
    component: Homepage,
  },
  {
    path: "/privacy",
    name: "privacy-policy",
    component: () => import("../pages/PrivacyPolicy/PrivacyPolicy.vue"),
  },
  {
    path: "*",
    redirect: { name: "home-page" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
