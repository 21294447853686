import Homepage from "./pages/Homepage/Homepage.vue";

export default {
  name: "App",
  data() {
    return {
      loading: true,
      language: null
    };
  },
  components: {
    Homepage,
  },
  mounted() {
    this.language = navigator.language || navigator.userLanguage;

    this.$root.$on("loading", () => {
      this.loading = true;
    });
    this.$root.$on("loaded", () => {
      this.loading = false;
    });
    this.$root.$on("languageChange", newLang => {
      this.language = newLang;
      localStorage.lang = newLang;
      document.documentElement.setAttribute('lang', newLang);
    });
  },
};
