import Vue from "vue";
import App from "./App.vue";
import PrismicVue from "@prismicio/vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import router from "./router";
import{ init } from 'emailjs-com';

init("user_ePEaqStA67d8GlloCb34l");

Vue.config.productionTip = false;

const accessToken = ""; // Leave empty if your repo is public
const endpoint = "https://kimmerle1926.cdn.prismic.io/api/v2"; // Use your repo name

library.add(faChevronLeft, faChevronRight, faPhone, faEnvelope, faTimes, faLinkedin);
Vue.component("font-awesome-icon", FontAwesomeIcon);

const EmilysWebsitesFooter = () =>
  import("./components/EmilysWebsitesFooter/EmilysWebsitesFooter.vue");
Vue.component("emilys-websites-footer", EmilysWebsitesFooter);

const KimmerleFooter = () =>
  import("./components/KimmerleFooter/KimmerleFooter.vue");
Vue.component("kimmerle-footer", KimmerleFooter);

const TestimonialSlider = () =>
  import("./components/TestimonialSlider/TestimonialSlider.vue");
Vue.component("testimonial-slider", TestimonialSlider);

// Register plugin
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
