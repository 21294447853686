export default {
  props: ["testimonials"],
  name: "testimonial-slider",
  data() {
    return {
      index: 0,
    };
  },
  methods: {
    next() {
      const slider = this.$refs.sliderContainer;
      slider.scrollLeft += slider.clientWidth;
    },
    prev() {
      const slider = this.$refs.sliderContainer;
      slider.scrollLeft -= slider.clientWidth;
    },
  },
};
