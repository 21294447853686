import TestimonialSlider from "../../components/TestimonialSlider/TestimonialSlider.vue";
import emailjs from 'emailjs-com';

export default {
  name: "Homepage",
  components: {
    TestimonialSlider,
  },
  data() {
    return {
      language: null,
      document: null,
      form: { name: null, email: null, message: null },
      showError: false,
      showSuccess: false,
      sending: false,
      showLanguagePicker: false,
      selectedImage: { url: null, alt: null },
      showOverlay: false,
    };
  },
  methods: {
    getContent() {
      this.$root.$emit("loading");
      //Query to get home content
      this.$prismic.client.getSingle("homepage", { lang: this.language }).then((document) => {
        if (document) {
          this.document = document;
          this.$root.$emit("loaded");
        }

        this.showLanguagePicker = false;
      });
    },
    fadedBackgroundImageStyle(imageUrl, fadeColour) {
      return `linear-gradient(${fadeColour}, ${fadeColour}), url(${imageUrl})`;
    },
    sendEnquiry() {
      this.sending = true;
      this.showError = false;

      emailjs.sendForm('service_fijhacc', 'contact_form', '#enquiry-form')
        .then((result) => {
          this.showSuccess = true;
          console.log('Success', result.status, result.text);
        }, (error) => {
          this.showError = true;
          this.sending = false;
          console.error(error)
        });
    },
    toggleLanguagePicker() {
      this.showLanguagePicker = !this.showLanguagePicker;
    },
    changeLanguage(newLang) {
      this.language = newLang;
      this.getContent();
      this.$root.$emit("languageChange", newLang);
    },
    prev() {
      const slider = this.$refs.sliderImageContainer;
      slider.scrollLeft -= slider.clientWidth / 3;
    },
    next() {
      const slider = this.$refs.sliderImageContainer;
      slider.scrollLeft += slider.clientWidth / 3;
    },
    selectImage(event) {
      if (window.innerWidth <= 600) {
        return;
      }
      
      this.selectedImage = { url: event.target.src, alt: event.target.alt };
      this.showOverlay = true;

      document.body.classList.add("no-scroll");
    },
    hideOverlay() {
      this.showOverlay = false;
      setTimeout(() => {
        document.body.classList.remove("no-scroll");
      }, 750);
    },
  },
  computed: {
    heroStyle() {
      return {
        backgroundImage: `url(${this.document.data.title_background_image.url})`,
      };
    },
    processStyle() {
      return {
        backgroundImage: `url(${this.document.data.process_background_image.url})`,
        backgroundAttachment: "fixed",
      };
    },
    testimonialsStyle() {
      return {
        backgroundImage: `url(${this.document.data.testimonials_background_image.url})`,
        backgroundAttachment: "fixed",
      };
    },
    flagImage() {
      switch (this.language) {
        case 'fr-fr':
          return 'french.svg';
        case 'en-gb':
          return 'english.svg';
        case 'de-de':
          return 'german.svg';
        default:
          return 'french.svg';
      }
    }
  },
  created() {
    // const userLanguage = localStorage.lang || navigator.language || navigator.userLanguage;

    // if (userLanguage.startsWith('en')) {
    //   this.language = 'en-gb';
    // } else if (userLanguage.startsWith('de')) {
    //   this.language = 'de-de';
    // } else {
    //   this.language = 'fr-fr';
    // }

    this.language = 'fr-fr';

    document.documentElement.setAttribute('lang', this.language);

    this.getContent();
  },
};
